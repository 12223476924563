<template>
  <g id="surface1">
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.968 18.332C2.063 16.558 1 13.53 1 10.72 1 5.353 5.351 1 10.72 1c5.237 0 9.507 4.143 9.71 9.33"
        stroke="#23303D"
        stroke-width="1.1"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M20.44 10.72c0 5.05-3.133 8.778-7.414 8.94-8.294 0-6.866-6.217-6.95-11.287"
        stroke="#23303D"
        stroke-width="1.1"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M6.805 11.232h-.55v1.1h.55v-1.1zm4.147 1.1a.55.55 0 0 0 0-1.1v1.1zm-4.147 0h4.147v-1.1H6.805v1.1z"
        fill="#23303D"
      />
    </svg>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
